<template >
  <div>
    <!-- ======= Breadcrumbs ======= -->
    <section class="breadcrumbs">
      <div class="container">

        <ol>
          <li><a @click="routeTo('home')">Home</a></li>
          <li><a>Opportunities</a></li>

        </ol>
        <h2>Opportunities</h2>

      </div>
    </section>

    <section id="values" class="values">
      <div class="container" data-aos="fade-up">

        <div class="row">
          <Loading v-if="loading"></Loading>
          <b-table-simple hover v-if="!loading" class="carreras-table" responsive="sm">


            <b-thead>
              <b-tr>
                <b-th style="background:#012970;color:white" class="col-9">Title</b-th>
                <b-th class="col-3">Deadline</b-th>
              </b-tr>


            </b-thead>
            <b-tbody>

              <b-tr v-for="(job, index) in jobs" :key="index" @click="routeTo('career-detail', job)">
                <b-th >{{ job.position }}</b-th>
                <b-th>
                  <time datetime="2020-01-01">{{ job.actualDate }}</time>
                </b-th>

              </b-tr>


            </b-tbody>

          </b-table-simple>
        </div>
        <div class="horizontal-center margin-20">

          <b-button variant="outline-dark" pill v-b-modal.modal-xl>Work with us</b-button>
        </div>

        <b-modal id="modal-xl" size="xl" title="Extra Large Modal" ok-only ok-title="Cancel" hide-header>
          <b-row>
            <b-col>
              <b-card bg-variant="light" header="For Companies" class="text-center">
                <b-card-text>
                  Tell us about your company and the products or services you
                  offer
                </b-card-text>
                <b-button @click="type = 'company'" variant="primary">Click here</b-button>
              </b-card>
            </b-col>
            <b-col>
              <b-card bg-variant="light" header="Individual professionals" class="text-center">
                <b-card-text>
                  Job Seekers and consultants, share your CVs with us
                </b-card-text>
                <b-button @click="type = 'employee'" variant="primary">Click here</b-button>
              </b-card>
            </b-col>
          </b-row>

          <span v-if="type == 'employee'" class="row">
            <h3 class="text-center">Individual professionals</h3>
            <div class="col-md-6">
              <p>
                <b-input v-model="userForm.full_name" placeholder="Full Name">
                </b-input>
              </p>
              <p v-if="userFormError.full_name" class="error-message text-center">
                Full Name can't be empty
              </p>
            </div>

            <div class="col-md-6">
              <p>

                <b-input v-model="userForm.telephone" type="text" placeholder="Telephone">
                </b-input>
              </p>

              <p v-if="userFormError.telephone" class="error-message text-center">
                Telephone can't be empty
              </p>
            </div>
            <div class="col-md-6">
              <p>

                <b-input v-model="userForm.email" type="text" placeholder="Email">
                </b-input>
              </p>

              <p v-if="userFormError.email" class="error-message text-center">
                Email can't be empty
              </p>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-2">
                  <label>Date of Birth</label>
                </div>
                <div class="col-md-10">
                  <b-input v-model="userForm.Date_of_birth" type="date" placeholder="Date of Birth"
                    style="width: 100%;">
                  </b-input>
                </div>

              </div>

              <p v-if="userFormError.Date_of_birth" class="error-message text-center">
                Date of Birth can't be empty
              </p>
            </div>


            <div class="col-md-6">
              <p>

                <b-input v-model="userForm.address" type="text" placeholder="Address">
                </b-input>
              </p>

              <p v-if="userFormError.address" class="error-message text-center">
                Address can't be empty
              </p>
            </div>
            <div class="col-md-6">
              <p>

                <b-input v-model="userForm.expertise_area" type="text" placeholder="Expertise Area">
                </b-input>
              </p>

              <p v-if="userFormError.expertise_area" class="error-message text-center">
                Expertise Area can't be empty
              </p>
            </div>
            <div class="col-md-6">
              <p>

                <b-input v-model="userForm.career_level" type="text" placeholder="Career Level">
                </b-input>
              </p>

              <p v-if="userFormError.career_level" class="error-message text-center">
                Career Level can't be empty
              </p>
            </div>
            <div class="col-md-6">
              <p>

                <b-input v-model="userForm.gross_current_Salary" type="number" placeholder="Gross Current Salary">
                </b-input>
              </p>

              <p v-if="userFormError.gross_current_Salary" class="error-message text-center">
                Gross Current Salary can't be empty
              </p>
            </div>
            <div class="col-md-6">
              <p>

                <b-input v-model="userForm.availability" type="text" placeholder="Availability">
                </b-input>
              </p>

              <p v-if="userFormError.availability" class="error-message text-center">
                Availability can't be empty
              </p>
            </div>
            <div class="col-md-6">
              <p>

                <b-input v-model="userForm.employment_type" type="text" placeholder="Employment Type">
                </b-input>
              </p>

              <p v-if="userFormError.employment_type" class="error-message text-center">
                Employment Type can't be empty
              </p>
            </div>
            <div class="col-md-6">
              <p>

                <b-input v-model="userForm.years_of_experience" type="number" placeholder="Years of experience">
                </b-input>
              </p>

              <p v-if="userFormError.years_of_experience" class="error-message text-center">
                Years of experience can't be empty
              </p>
            </div>
            <div class="col-md-6">
              <p>

                <b-input v-model="userForm.regional_experience" placeholder="Country/Regional Experience">
                </b-input>
              </p>

              <p v-if="userFormError.regional_experience" class="error-message text-center">
                Regional experience can't be empty
              </p>
            </div>
            <div class="col-md-12 row" style="margin-top:10px">
              <label for="" class="col-md-12">Education (including all college or university degrees)</label>
              <div class="col-md-11 row" v-for="(education,index) in userForm.Education" :key="index"
                style="margin-bottom:10px">

                <div class="col-md-3">
                  <b-input v-model="userForm.Education[index].name_of_institution" placeholder="Name Of Institution">
                  </b-input>
                  <p v-if="userFormError.name_of_institution" class="error-message text-center">
                    Name Of Institution can't be empty
                  </p>
                </div>
                <div class="col-md-2">
                  <b-input v-model="userForm.Education[index].location_of_institution" placeholder="Location Of Institution">
                  </b-input>
                  <p v-if="userFormError.location_of_institution" class="error-message text-center">
                    Location Of Institution can't be empty
                  </p>
                </div>
                <div class="col-md-2">
                  <b-input v-model="userForm.Education[index].degree" placeholder="Degree">
                  </b-input>
                  <p v-if="userFormError.degree" class="error-message text-center">
                    Degree can't be empty
                  </p>
                </div>
                <div class="col-md-2">
                  <b-input v-model="userForm.Education[index].major" placeholder="Major">
                  </b-input>
                  <p v-if="userFormError.major" class="error-message text-center">
                    Major can't be empty
                  </p>
                </div>
                <div class="col-md-3">
                  <div class="row center-element">
                    <div class="col-md-2">
                      <label for="">Date</label>
                    </div>
                    <div class="col-md-9">
                      <b-input v-model="userForm.Education[index].date" type="date" placeholder="Date">
                      </b-input>
                    </div>
                  </div>


                  <p v-if="userFormError.date" class="error-message text-center">
                    Date can't be empty
                  </p>
                </div>

              </div>
              <div class="col-md-1">
                <b-button @click="userForm.Education.push({name_of_institution:null,
                location_of_institution:null,
                degree:null,
                major:null,
                date:null})">+</b-button>
              </div>
            </div>

            <div class="col-md-12 row" style="margin-top:10px">
              <label for="" class="col-md-12">Language Proficiency</label>
              <div class="col-md-11 row" v-for="(education,index) in userForm.language_proficiency" :key="index"
                style="margin-bottom:10px">

                <div class="col-md-3">
                  <b-input v-model="userForm.language_proficiency[index].language" placeholder="Language">
                  </b-input>
                  <p v-if="userFormError.language" class="error-message text-center">
                    Language can't be empty
                  </p>
                </div>
                <div class="col-md-3">
                  <b-input v-model="userForm.language_proficiency[index].speaking" placeholder="Speaking">
                  </b-input>
                  <p v-if="userFormError.speaking" class="error-message text-center">
                    Speaking can't be empty
                  </p>
                </div>
                <div class="col-md-3">
                  <b-input v-model="userForm.language_proficiency[index].reading" placeholder="Reading">
                  </b-input>
                  <p v-if="userFormError.reading" class="error-message text-center">
                    Reading can't be empty
                  </p>
                </div>
                <div class="col-md-3">
                  <b-input v-model="userForm.language_proficiency[index].writing" placeholder="Writing">
                  </b-input>
                  <p v-if="userFormError.writing" class="error-message text-center">
                    Writing can't be empty
                  </p>
                </div>


              </div>
              <div class="col-md-1">
                <b-button @click="userForm.language_proficiency.push({language:null,
                speaking:null,
                reading:null,
                writing:null,})">+</b-button>
              </div>
            </div>

            <div class="col-md-12" style="margin-top:10px">
              <b-form-textarea placeholder="Additional Skills:" rows="5"></b-form-textarea>
            </div>



            <div class="col-md-12 row" style="margin-top:10px">
              <label for="" class="col-md-12">Employment History</label>
              <div class="col-md-11 row center-element" v-for="(education,index) in userForm.employment_history"
                :key="index" style="margin-bottom:10px">

                <div class="col-md-2">
                  <b-input v-model="userForm.employment_history[index].position_title" placeholder="Position Title">
                  </b-input>
                  <p v-if="userFormError.position_title" class="error-message text-center">
                    Position Title can't be empty
                  </p>
                </div>
                <div class="col-md-2">
                  <b-input v-model="userForm.employment_history[index].employers_name" placeholder="Employers Name">
                  </b-input>
                  <p v-if="userFormError.employers_name" class="error-message text-center">
                    Employers Name can't be empty
                  </p>
                </div>
                <div class="col-md-3">
                  <b-input v-model="userForm.employment_history[index].summary_of_position" placeholder="Summary of Position">
                  </b-input>
                  <p v-if="userFormError.summary_of_position" class="error-message text-center">
                    Summary of Position Of can't be empty
                  </p>
                </div>
                <div class="col-md-2">
                  <b-input v-model="userForm.employment_history[index].monthly_gross_rate" placeholder="Monthly Gross Rate">
                  </b-input>
                  <p v-if="userFormError.monthly_gross_rate" class="error-message text-center">
                    Monthly Gross Rate Of can't be empty
                  </p>
                </div>
                <div class="row col-md-3">
                  <label for="" class="text-center">Date Of Employment </label>
                  <div class="col-md-6">
                    <div class="row center-element">
                      <div class="col-md-4">
                        From
                      </div>
                      <div class="col-md-8">
                        <b-input v-model="userForm.employment_history[index].date_Of_employment_from" placeholder="From" type="date">
                        </b-input>
                      </div>


                    </div>

                    <p v-if="userFormError.date_Of_employment_from" class="error-message text-center">
                      Date Of Employment From Of can't be empty
                    </p>
                  </div>
                  <div class="col-md-6">
                    <div class="row center-element">
                      <div class="col-md-4">
                        To
                      </div>
                      <div class="col-md-8">
                        <b-input v-model="userForm.employment_history[index].date_Of_employment_to" placeholder="To" type="date"></b-input>
                      </div>


                    </div>

                    <p v-if="userFormError.date_Of_employment_to" class="error-message text-center">
                      Date Of Employment To Of can't be empty
                    </p>
                  </div>
                </div>





              </div>
              <div class="col-md-1">
                <b-button @click="userForm.employment_history.push( {
                  position_title:null,
                  employers_name:null,
                  summary_of_position:null,
                  date_Of_employment_from:null,
                  date_Of_employment_to:null,
                  monthly_gross_rate:null,
      
                })">+</b-button>
              </div>
            </div>


            <div class="col-md-12 row" style="margin-top:10px">
              <label for="" class="col-md-12">Specific Consultant Services</label>
              <div class="col-md-11 row" v-for="(education,index) in userForm.specific_consultant_services" :key="index"
                style="margin-bottom:10px">

                <div class="col-md-3">
                  <b-input v-model="userForm.specific_consultant_services[index].services_performed" placeholder="Services Performed">
                  </b-input>
                  <p v-if="userFormError.services_performed" class="error-message text-center">
                    Services Performed Of Institution can't be empty
                  </p>
                </div>
                <div class="col-md-3">
                  <b-input v-model="userForm.specific_consultant_services[index].employers_name"
                    placeholder="Employer’s Name and Address Point of Contact">
                  </b-input>
                  <p v-if="userFormError.employers_name" class="error-message text-center">
                    Employers Name can't be empty
                  </p>
                </div>
                <div class="col-md-3">
                  <b-input v-model="userForm.specific_consultant_services[index].level_of_effort" placeholder="level of effort (LOE)">
                  </b-input>
                  <p v-if="userFormError.level_of_effort" class="error-message text-center">
                    level of effort (LOE) can't be empty
                  </p>
                </div>

                <div class="col-md-3">
                  <b-input v-model="userForm.specific_consultant_services[index].daily_rate" placeholder="Daily Rate">
                  </b-input>
                  <p v-if="userFormError.daily_rate" class="error-message text-center">
                    Daily Rate can't be empty
                  </p>
                </div>

              </div>
              <div class="col-md-1">
                <b-button @click="userForm.specific_consultant_services.push({
services_performed:null,
employers_name:null,
level_of_effort:null,
daily_rate:null,
                })">+</b-button>
              </div>
            </div>
            <p style="margin-top:10px">

              <label for="">Resume</label>
              <ImagPicker :imageData="{ maxNoImg: 1, imageNo: 'Resume' }"></ImagPicker>
            </p>

            <p v-if="userFormError.resume" class="error-message text-center">
              Resume can't be empty
            </p>

            <p>

            </p>

            <p class="text-center">
              <b-button variant="primary" @click="submitResume">Submit</b-button>
            </p>
          </span>
          <span v-if="type == 'company'">
            <h3 class="text-center">Company Information</h3>

            <br />
            <b-input id="name" v-model="company.name" placeholder="Name of business"></b-input>
            <p v-if="companyError.name" class="error-message text-center">
              Name can't be empty
            </p>
            <br />
            <b-input id="email" v-model="company.email" placeholder="Email"></b-input>
            <p v-if="companyError.email" class="error-message text-center">
              email can't be empty
            </p>
            <p v-if="companyError.invalidEmail" class="error-message text-center">
              Invalid Email Address
            </p>
            <br />
            <VuePhoneNumberInput id="phone" @update="onUpdate" default-country-code="ET" v-model="countrycode" />
            <p v-if="companyError.phone" class="error-message text-center">
              Phone can't be empty
            </p>
            <br />
            <b-textarea v-model="company.about_service" placeholder="About your service">
            </b-textarea>
            <p v-if="companyError.about_service" class="error-message text-center">
              About your service can't be empty
            </p>
            <br />
            <b-input v-model="company.head_office" placeholder="Head office"></b-input>
            <p v-if="companyError.head_office" class="error-message text-center">
              head Office can't be empty
            </p>
            <br />
            <b-input v-model="company.website" placeholder="Website (optional)"></b-input>
            <br />
            <b-textarea v-model="company.other_information" placeholder="Other information (optional)">
            </b-textarea>
            <br />
            <div class="text-center">
              <b-button variant="primary" @click="submiteCompany">Submit</b-button>
            </div>
          </span>
        </b-modal>
      </div>
    </section>
    <!-- End Values Section -->

  </div>
</template>
<script>
import { getFields, postFields } from "../assets/js/service";
import ImagPicker from "../web-admin/Common/ImageUpload.vue";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import Loading from './container/Loading.vue'


import Vue from 'vue';
export default {
  metaInfo: {
    // Children can override the title.
    title: 'Careers',
    // Result: My Page Title ← My Site
    // If a child changes the title to "My Other Page Title",
    // it will become: My Other Page Title ← My Site
    titleTemplate: '%s ← First Consult',
    // Define meta tags here.
    meta: [
      { property: "og:site_name", content: "First Consult Careers" },
      { property: "og:title", content: "First Consult Careers" },
      { property: "og:description", content: "First Consult is a leading economic development consulting firm implementing projects in Ethiopia. Founded in 2006, First Consult has grown to design and implement projects across the agriculture, manufacturing and service sectors. Our multidisciplinary teams combine a capacity to execute with a clarity of the local context. We have delivered at-scale real impact in terms of jobs & wealth creation, business formation & growth, and investment attraction & mobilisation." },
      { property: "og:image", content: "http://firstconsultet.com/img/favicons.fb3e6239.png" },
      { property: "og:url", content: "https//www.firstconsulet.com/career" },


      { name: "twitter:title", content: "First Consult Careers" },
      { name: "twitter:description", content: "First Consult is a leading economic development consulting firm implementing projects in Ethiopia. Founded in 2006, First Consult has grown to design and implement projects across the agriculture, manufacturing and service sectors. Our multidisciplinary teams combine a capacity to execute with a clarity of the local context. We have delivered at-scale real impact in terms of jobs & wealth creation, business formation & growth, and investment attraction & mobilisation." },
      { name: "twitter:image", content: "http://firstconsultet.com/img/favicons.fb3e6239.png" },
      { property: "twitter:url", content: "https//www.firstconsulet.com/career" },
      { name: "twitter:card", content: "summary" },

      { 'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8' },
      { property: "Title", content: "First Consult Opportunity" },
      { name: 'description', content: 'First Consult Opportunity.' }],
    script: [
      { src: 'https://platform.twitter.com/widgets.js', async: true },
    ],
  },
  components: {
    ImagPicker,
    VuePhoneNumberInput,
    Loading
  },
  data() {
    return {
      jobs: null,
      type: "",
      companyError: {
        name: null,
        email: null,
        phone: null,
        about_service: null,
        head_office: null,
        invalidEmail: null,
      },
      company: {
        name: null,
        email: null,
        phone: null,
        about_service: null,
        head_office: null,
        website: null,
        other_information: null,
      },
      countrycode: null,
      userFormError: {

        full_name: null,
        age: null,
        years_of_experience: null,
        regional_experience: null,
        resume: null,
      },
      userForm: {
        full_name: null,
        telephone: null,
        email: null,
        Date_of_birth: null,
        address: null,
        expertise_area: null,
        career_level: null,
        employment_type: null,
        gross_current_Salary:null,
availability:null,
        years_of_experience: null,
        regional_experience: null,

        resume: null,
        Education: [
          {
            name_of_institution: null,
            location_of_institution: null,
            degree: null,
            major: null,
          }
        ],
        language_proficiency: [
          {
            language: null,
            speaking: null,
            writing: null,
            reading: null,
          }
        ],
        employment_history: [
          {
            position_title: null,
            employers_name: null,
            summary_of_position: null,
            date_Of_employment_from: null,
            date_Of_employment_to: null,
            monthly_gross_rate: null,

          }
        ],
        specific_consultant_services: [{
          services_performed: null,
          employers_name: null,
          level_of_effort: null,
          daily_rate: null,
        }],
      },
      loading: false,
      results: null,
    };
  },
  watch: {
    "$store.state.loading": {
      handler() {
        this.loading = this.$store.state.loading;

      },
    }
  },
  mounted() {
    this.$store.commit('set', ['loading', true]);
    let token = localStorage.getItem("token");
    getFields("careers", false, { "type": "status", "value": true }, false, { value: "actualDate", type: "DESC" },).then((resp) => {
      this.jobs = resp.data;
      this.$store.commit('set', ['loading', false]);
    });
  },
  methods: {
    onUpdate(payload) {
      this.results = payload;
      if (this.countrycode) {
        this.company.phone = this.results.e164.substr(
          1,
          this.results.e164.length
        );
      }
    },
    validateEmail(value) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
        return false;
      } else {
        return true;
      }
    },
    routeTo(route, job) {
      if (route == "career-detail") {
        this.$store.commit("set", ["jobData", job]);
        this.$router.push(route + '/' + job.id);
      } else {
        this.$router.push(route);
      }

    },
    submiteCompany() {
      if (!this.company.name) {
        this.companyError.name = true;
      } else {
        this.companyError.name = false;
      }
      if (!this.company.email) {
        this.companyError.email = true;
      } else {
        this.companyError.email = false;
        if (this.validateEmail(this.company.email)) {
          this.companyError.invalidEmail = true;
        } else {
          this.companyError.invalidEmail = false;
        }
      }
      if (!this.countrycode) {
        this.companyError.phone = true;
      } else {
        this.companyError.phone = false;
      }
      if (!this.company.about_service) {
        this.companyError.about_service = true;
      } else {
        this.companyError.about_service = false;
      }
      if (!this.company.head_office) {
        this.companyError.head_office = true;
      } else {
        this.companyError.head_office = false;
      }
      if (
        this.company.name &&
        this.company.email &&
        !this.companyError.invalidEmail &&
        this.countrycode &&
        this.company.about_service &&
        this.company.head_office
      ) {
        let token = localStorage.getItem("token");
        postFields("Companies", this.company, token).then((resp) => {
          this.company = {
            name: null,
            email: null,
            phone: null,
            about_service: null,
            head_office: null,
            website: null,
            other_information: null,
          };
          Vue.$toast.success("Thank you for your interest to work with us. Your information has been recorded.", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,

            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
          this.$bvModal.hide("modal-xl");
        })
          .catch(e => {

            Vue.$toast.error(
              "connection refused", {
              position: "top-right",
              timeout: 5000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false
            }
            );
          })
          ;
      }
    },
    submitResume() {




if (!this.userForm.gross_current_Salary) {
        this.userFormError.gross_current_Salary = true;
      } else {
        this.userFormError.gross_current_Salary = false;
      }
      if (!this.userForm.availability) {
        this.userFormError.availability = true;
      } else {
        this.userFormError.availability = false;
      }


      if (!this.userForm.employment_type) {
        this.userFormError.employment_type = true;
      } else {
        this.userFormError.employment_type = false;
      }
      if (!this.userForm.career_level) {
        this.userFormError.career_level = true;
      } else {
        this.userFormError.career_level = false;
      }
      if (!this.userForm.expertise_area) {
        this.userFormError.expertise_area = true;
      } else {
        this.userFormError.expertise_area = false;
      }
      if (!this.userForm.address) {
        this.userFormError.address = true;
      } else {
        this.userFormError.address = false;
      }
      if (!this.userForm.email) {
        this.userFormError.email = true;
      } else {
        this.userFormError.email = false;
      }
      if (!this.userForm.telephone) {
        this.userFormError.telephone = true;
      } else {
        this.userFormError.telephone = false;
      }
      if (!this.userForm.full_name) {
        this.userFormError.full_name = true;
      } else {
        this.userFormError.full_name = false;
      }
      if (!this.userForm.Date_of_birth) {
        this.userFormError.Date_of_birth = true;
      } else {
        this.userFormError.Date_of_birth = false;
      }
      if (!this.userForm.years_of_experience) {
        this.userFormError.years_of_experience = true;
      } else {
        this.userFormError.years_of_experience = false;
      }
      if (!this.userForm.regional_experience) {
        this.userFormError.regional_experience = true;
      } else {
        this.userFormError.regional_experience = false;
      }
      if (!this.$store.state.filePDF || this.$store.state.filePDF.length == 0) {
        this.userFormError.resume = true;
      } else {
        this.userFormError.resume = false;
      }
      if (
        this.$store.state.filePDF &&
        this.$store.state.filePDF.length != 0 &&
        this.userForm.full_name &&
        this.userForm.years_of_experience &&
        this.userForm.gross_current_Salary &&
this.userForm.availability &&
this.userForm.employment_type &&
this.userForm.career_level &&
this.userForm.expertise_area &&
this.userForm.address &&
this.userForm.email &&
this.userForm.telephone &&
this.userForm.Date_of_birth &&
        this.userForm.regional_experience
      ) {
        let token = localStorage.getItem("token");
        this.userForm.resume = this.$store.state.filePDF;
        this.userForm.type = "upload_only";
        console.log(this.userForm)
        postFields("jobSeekers", this.userForm, token).then((resp) => {
          this.userForm = {
            full_name: null,
            age: null,
            years_of_experience: null,
            regional_experience: null,
            resume: null,
          };
          Vue.$toast.success("Thank you for your interest to work with us. Your information has been recorded.", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,

            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
          this.$bvModal.hide("modal-xl");
        }).catch(e => {
          Vue.$toast.error(
            "connection refused", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false
          }
          );
        });
      }
    },
  },
};
</script>
<style>
.carreras-table th,
.carreras-table tr {
  font-weight: normal;
}

.modal-xl input {
  font-size: 0.8rem;
}
</style>